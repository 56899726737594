import { ProfileDetailsVO } from '../profile/profilevisitdetailsvo';
import { CartItem } from './cartitem';
import { SlotBookingDetails } from './slotbookingdetails';
import { BaseResponse } from '../base/baseresponse';
import { Taxes } from './taxes';
import { PaymentGatewayParameters } from './paymentGatewayParameters';
import { BasketDiscount } from '../package/basketdiscount';
import { Payment } from './payment';

export class BasketRequest extends BaseResponse {
    public orderId: any;
    public bookingSource: number;
    public transactionSource: number;
    public transactionType: number;
    public transactionId: string;
    public paymentId: string;
    public profileId: number;
    public parentProfileId: number;

    public slotBookingDetailsList: SlotBookingDetails[] = [];
    public cartItemList: CartItem[] = new Array();
    public canPayOnVist: boolean;
    public canPayNow: boolean;
    public createdTimestamp: number;
    public updatedTimestamp: number;

    public orderPaymentStatus: number;
    public paidItemsCount: number;
    public notPaidItemsCount: number;
    public Type: string;   //temporary
    public paymentStatus: number;   //temporary
    public totalTaxes: Taxes;
    public totalOriginalAmount: number;
    public totalPackageDiscountAmount: number;
    public totalOtherDiscountAmount: number;
    public totalTaxationAmount: number;
    public totalFinalAmount: number;
    public paymentSource: number;

    public pdfUrlWithHeader: string;
    public pdfUrlWithoutHeader: string;

    public payUParameters: PaymentGatewayParameters;

    public parentProfileDetails: ProfileDetailsVO;

    public totalDeliveryAmount: number;
    public totalAmountPaid: number;
    public totalAmountToPay: number;
    public totalPromotionalDiscounts: number;
    public totalFollowUpDiscounts: number;
    public totalPartnerDiscounts: number;
    public totalCouponDiscounts: number;
    public totalPrivelegeDiscounts: number;
    public totalDiscounts: number;
    public totalCGSTTaxationAmount: number;
    public totalSGSTTaxationAmount: number;
    public totalIGSTTaxationAmount: number;

    // local
    public privilegeCardSavingAmount: number;
    public freePrivilegeCardEligible: boolean

    public static calculatePayments(basketRequest: BasketRequest): BasketRequest {
        basketRequest.totalFinalAmount = 0;
        basketRequest.totalDeliveryAmount = 0;
        basketRequest.totalOriginalAmount = 0;

        basketRequest.totalTaxationAmount = 0;
        basketRequest.totalCGSTTaxationAmount = 0;
        basketRequest.totalSGSTTaxationAmount = 0;
        basketRequest.totalIGSTTaxationAmount = 0;

        basketRequest.totalPackageDiscountAmount = 0;
        basketRequest.totalFollowUpDiscounts = 0;
        basketRequest.totalPartnerDiscounts = 0;
        basketRequest.totalCouponDiscounts = 0;
        basketRequest.totalPromotionalDiscounts = 0;
        basketRequest.totalPrivelegeDiscounts = 0;
        basketRequest.totalDiscounts = 0;

        basketRequest.totalAmountPaid = 0;
        basketRequest.totalAmountToPay = 0;

        if (basketRequest?.slotBookingDetailsList?.[0]) {
            basketRequest?.slotBookingDetailsList?.forEach((slot) => {

                slot?.basketDiscount?.forEach((basketDiscount) => {
                    if (basketDiscount.discountAmount) {
                        if (basketDiscount.type == BasketDiscount.DOCTOR_FOLLOWUP) basketRequest.totalFollowUpDiscounts += basketDiscount.discountAmount;
                        else if (basketDiscount.type == BasketDiscount.PARTNER_DISCOUNT) basketRequest.totalPartnerDiscounts += basketDiscount.discountAmount;
                        else if (basketDiscount.type == BasketDiscount.COUPON_DISCOUNT) basketRequest.totalCouponDiscounts += basketDiscount.discountAmount;
                        else if (basketDiscount.type == BasketDiscount.PROMOTIONAL_DISCOUNT) basketRequest.totalPromotionalDiscounts += basketDiscount.discountAmount;
                        else if (basketDiscount.type == BasketDiscount.PRIVELEGE_DISCOUNT) basketRequest.totalPrivelegeDiscounts += +basketDiscount.discountAmount;
                    }
                })

                basketRequest.totalFinalAmount += slot?.payment?.finalAmount ? slot?.payment?.finalAmount : 0;
                basketRequest.totalDeliveryAmount += slot?.deliveryAmount ? slot?.deliveryAmount : 0;
                basketRequest.totalOriginalAmount += slot?.payment?.originalAmount ? slot?.payment?.originalAmount : 0;

                basketRequest.totalTaxationAmount += slot?.payment?.taxationAmount ? slot?.payment?.taxationAmount : 0;
                basketRequest.totalCGSTTaxationAmount += slot?.payment?.taxes?.cgstAmount ? slot?.payment?.taxes?.cgstAmount : 0;
                basketRequest.totalSGSTTaxationAmount += slot?.payment?.taxes?.sgstAmount ? slot?.payment?.taxes?.sgstAmount : 0;
                basketRequest.totalIGSTTaxationAmount += slot?.payment?.taxes?.igstAmount ? slot?.payment?.taxes?.igstAmount : 0;

                basketRequest.totalPackageDiscountAmount += slot?.payment?.packageDiscountAmount ? slot?.payment?.packageDiscountAmount : 0;
                basketRequest.totalOtherDiscountAmount += slot?.payment?.otherDiscountAmount ? slot?.payment?.otherDiscountAmount : 0;

                if (slot?.payment?.paymentStatus == Payment.PAYMENT_STATUS_PAID) {
                    basketRequest.totalAmountPaid += slot?.payment?.finalAmount ? slot?.payment?.finalAmount : 0;
                } else if (slot?.payment?.partialPayment?.paymentStatus == Payment.PAYMENT_STATUS_PAID) {
                    basketRequest.totalAmountPaid += slot?.payment?.amountPaid;
                    basketRequest.totalAmountToPay += slot?.payment?.amountToBePaid;
                } else {
                    basketRequest.totalAmountToPay += slot?.payment?.finalAmount ? slot?.payment?.finalAmount : 0;
                }
            });
        }

        if (basketRequest?.cartItemList?.[0]) {
            basketRequest?.cartItemList?.forEach((cart) => {

                cart?.basketDiscount?.forEach((basketDiscount) => {
                    if (basketDiscount.discountAmount) {
                        if (basketDiscount.type == BasketDiscount.DOCTOR_FOLLOWUP) basketRequest.totalFollowUpDiscounts += +basketDiscount.discountAmount;
                        else if (basketDiscount.type == BasketDiscount.PARTNER_DISCOUNT) basketRequest.totalPartnerDiscounts += +basketDiscount.discountAmount;
                        else if (basketDiscount.type == BasketDiscount.COUPON_DISCOUNT) basketRequest.totalCouponDiscounts += +basketDiscount.discountAmount;
                        else if (basketDiscount.type == BasketDiscount.PROMOTIONAL_DISCOUNT) basketRequest.totalPromotionalDiscounts += +basketDiscount.discountAmount;
                        else if (basketDiscount.type == BasketDiscount.PRIVELEGE_DISCOUNT) basketRequest.totalPrivelegeDiscounts += basketDiscount.discountAmount;
                    }
                })

                basketRequest.totalFinalAmount += cart?.payment?.finalAmount ? cart?.payment?.finalAmount : 0;
                basketRequest.totalDeliveryAmount += cart?.deliveryAmount ? cart?.deliveryAmount : 0;
                basketRequest.totalOriginalAmount += cart?.payment?.originalAmount ? cart?.payment?.originalAmount : 0;

                basketRequest.totalTaxationAmount += cart?.payment?.taxationAmount ? cart?.payment?.taxationAmount : 0;
                basketRequest.totalCGSTTaxationAmount += cart?.payment?.taxes?.cgstAmount ? cart?.payment?.taxes?.cgstAmount : 0;
                basketRequest.totalSGSTTaxationAmount += cart?.payment?.taxes?.sgstAmount ? cart?.payment?.taxes?.sgstAmount : 0;
                basketRequest.totalIGSTTaxationAmount += cart?.payment?.taxes?.igstAmount ? cart?.payment?.taxes?.igstAmount : 0;

                basketRequest.totalPackageDiscountAmount += cart.payment?.packageDiscountAmount ? cart.payment?.packageDiscountAmount : 0;
                basketRequest.totalOtherDiscountAmount += cart.payment?.otherDiscountAmount ? cart.payment?.otherDiscountAmount : 0;
                if (cart.payment?.paymentStatus == Payment.PAYMENT_STATUS_PAID) {
                    basketRequest.totalAmountPaid += cart.payment?.finalAmount ? cart.payment?.finalAmount : 0;
                } else if (cart?.payment?.partialPayment?.paymentStatus == Payment.PAYMENT_STATUS_PAID) {
                    basketRequest.totalAmountPaid += cart?.payment?.amountPaid;
                    basketRequest.totalAmountToPay += cart?.payment?.amountToBePaid;
                } else {
                    basketRequest.totalAmountToPay += cart.payment?.finalAmount ? cart.payment?.finalAmount : 0;
                }
            });
        }
        basketRequest.totalFinalAmount = basketRequest.totalFinalAmount - basketRequest.totalAmountPaid - basketRequest.totalDiscounts;
        basketRequest.totalDiscounts = (
            +basketRequest.totalFollowUpDiscounts +
            +basketRequest.totalPartnerDiscounts +
            +basketRequest.totalCouponDiscounts +
            +basketRequest.totalPromotionalDiscounts +
            +basketRequest.totalPackageDiscountAmount +
            +basketRequest.totalPrivelegeDiscounts
        );
        return basketRequest;
    }
}